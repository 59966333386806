<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-row align-v="stretch">
      <b-col
        sm="12"
        md="7"
        xl="8"
      >
        <b-card class="h-100">
          <b-row align-h="center">
            <b-col
              cols="12"
              sm="10"
            >
              <div v-if="Object.keys(selectedCategory).length !== 0">
                <b-row
                  class="mb-1"
                  @click="
                    () => {
                      selectedCategory = {};
                    }
                  "
                >
                  <b-col style="cursor:pointer">
                    <feather-icon
                      v-if=" $i18n.isRTL"
                      icon="ArrowRightIcon"
                      size="14"
                    />
                    <feather-icon
                      v-else
                      icon="ArrowLeftIcon"
                      size="14"
                    />
                  </b-col>
                </b-row>
                <b-row
                  class="mb-1"
                  style="font-size: 1.5rem;"
                >
                  <b-col cols="auto">
                    <span>{{ $t("topic") }}</span>
                    <span>:</span>
                  </b-col>
                  <b-col cols="auto">
                    {{ selectedCategory.title }}
                  </b-col>
                </b-row>
                <ValidationObserver v-slot="{ invalid }">
                  <b-form @submit.prevent="onSubmit">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('title')"
                      rules="required"
                    >
                      <b-form-group
                        id="title"
                        :label="$t('createTicketPage.form.ticket_title')"
                        label-for="title"
                      >
                        <b-form-input
                          id="title"
                          v-model="form.title"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('context')"
                      rules="required"
                    >
                      <b-form-group
                        id="context"
                        label=""
                        label-for="context"
                      >
                        <b-form-textarea
                          id="context"
                          v-model="form.context"
                          :label="$t('createTicketPage.form.ticket_context')"
                          :placeholder="
                            $t('createTicketPage.form.ticket_context_description')
                          "
                          rows="8"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </b-form-group>
                    </validation-provider>
                    <b-row
                      align-v="center"
                      align-h="between"
                    >
                      <b-col
                        cols="12"
                        lg="auto"
                      >
                        <ValidationProvider
                          v-slot="{ errors, validate }"
                          :name="$t('image')"
                          :rules="'image|size:'+MAX_IMAGE_UPLOAD_SIZE"
                        >
                          <div
                            class="d-flex flex-row-reverse"
                          >
                            <feather-icon
                              v-if="form.file"
                              class="text-danger stroke-current"
                              icon="XIcon"
                              size="28"
                              variant="danger"
                              @click="form.file = null"
                            />
                            <b-form-file
                              v-model="form.file"
                              plain
                              accept="image/png, image/jpg, image/jpeg"
                              @change="validate"
                            />
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-col>
                      <b-col
                        cols="12"
                        lg="auto"
                        class="mt-1 mt-lg-0"
                      >
                        <b-button
                          type="submit"
                          variant="primary"
                          block
                          class="flex-end"
                          :disabled="invalid"
                        >
                          <span v-if="!isLoading">
                            {{ $t("createTicketPage.form.btn") }}</span>
                          <b-spinner
                            v-else
                            small
                            variant="light"
                            type="grow"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </div>
              <div v-else>
                <b-row>
                  {{ $t("createTicketPage.category_title") }}
                </b-row>
                <b-row
                  v-for="(item ,index) in categories"
                  :key="index"
                  class="w-100 border border-primary ml-0 my-2 py-2 rounded-lg shadow-sm categoryItem"
                  align-h="between"
                  @click="categorySelecting(item)"
                >
                  <b-col
                    cols="auto"
                    style="font-size:1.2rem"
                  >
                    {{ $t(item.title) }}
                  </b-col>
                  <b-col
                    cols="auto"
                  >
                    <feather-icon
                      v-if="$i18n.isRTL"
                      icon="ArrowLeftIcon"
                      size="14"
                    />
                    <feather-icon
                      v-else
                      icon="ArrowRightIcon"
                      size="14"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        sm="12"
        md="5"
        xl="4"
      >
        <b-card class="h-100 margin-query">
          <b-card-title>
            {{ $t("createTicketPage.desc_title") }}
          </b-card-title>
          <b-card-text
            v-for="item in $t('createTicketPage.description')"
            :key="item"
          >
            {{ item }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BCardTitle,
  BCardText,
  BFormTextarea,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import { required, image, size } from '@validations'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import TicketService from '@/services/apis/tickets'
import { resizeImage } from '@/utils'

const { createNewTicket, getTicketCategory } = new TicketService()
export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BCardTitle,
    BCardText,
    BFormTextarea,
    BFormFile,
    BSpinner,
    FeatherIcon,
  },

  data() {
    return {
      show: true,
      isLoading: false,
      categories: [],
      required,
      image,
      size,
      selectedCategory: {},
      form: {
        title: '',
        context: '',
        file: null,
      },
    }
  },
  computed: {
    MAX_IMAGE_UPLOAD_SIZE() {
      return process.env.VUE_APP_MAX_IMAGE_UPLOAD_SIZE
    },
  },
  created() {
    getTicketCategory()
      .then(({ data }) => {
        this.categories = data.results
      })
      .finally(() => {
        this.show = false
      })
  },

  methods: {
    async onSubmit() {
      this.isLoading = true
      const imageSize = (process.env.VUE_APP_RESIZE_IMAGE_SIZE ? process.env.VUE_APP_RESIZE_IMAGE_SIZE : 3072) * 1024
      const compressedFile = this.form.file ? await resizeImage(this.form.file, imageSize) : null
      const newTicket = {
        ticket_category_id: this.selectedCategory.id,
        context: this.form.context,
        title: this.form.title,
        file: compressedFile,
      }

      createNewTicket(newTicket)
        .then(({ data }) => {
          this.$swal({
            title: data?.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          this.$router.push({ name: 'tickets' })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    categorySelecting(item) {
      this.selectedCategory = item
    },
  },
}
</script>

<style scoped>
.categoryItem:hover{
  transition: all .2s;
  cursor: pointer;
  transform: translateY(-4px);
}

.categoryItem:nth-child(even){
  background-color: rgb(241, 246, 255);
}
@media (max-width: 767px) {
  .margin-query {
    margin-top: 20px;
  }
}

</style>
